define("ember-file-upload/components/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "iBf5dXDJ",
    "block": "[[[10,\"input\"],[15,1,[36,0]],[15,\"accept\",[36,1]],[15,\"capture\",[36,2]],[15,\"multiple\",[36,3]],[15,\"disabled\",[36,4]],[15,\"onchange\",[28,[37,5],[[30,0],\"change\"],[[\"value\"],[\"target.files\"]]]],[14,\"hidden\",\"\"],[14,4,\"file\"],[12],[13],[18,1,[[33,7]]]],[\"&default\"],false,[\"for\",\"accept\",\"capture\",\"multiple\",\"disabled\",\"action\",\"yield\",\"queue\"]]",
    "moduleName": "ember-file-upload/components/file-upload/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});