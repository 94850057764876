define("ember-concurrency/-private/task-decorators", ["exports", "@ember/object", "ember-concurrency/-private/task-factory", "ember-concurrency/-private/utils"], function (_exports, _object, _taskFactory, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.taskGroup = _exports.task = _exports.restartableTaskGroup = _exports.restartableTask = _exports.lastValue = _exports.keepLatestTaskGroup = _exports.keepLatestTask = _exports.enqueueTaskGroup = _exports.enqueueTask = _exports.dropTaskGroup = _exports.dropTask = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  function taskFromPropertyDescriptor(target, key, descriptor) {
    var params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    var initializer = descriptor.initializer,
      get = descriptor.get,
      value = descriptor.value;
    var taskFn;
    if (initializer) {
      taskFn = initializer.call(undefined);
    } else if (get) {
      taskFn = get.call(undefined);
    } else if (value) {
      taskFn = value;
    }
    taskFn.displayName = "".concat(key, " (task)");
    var tasks = new WeakMap();
    var options = params[0] || {};
    var factory = new _taskFactory.TaskFactory(key, taskFn, options);
    factory._setupEmberKVO(target);
    return {
      get: function get() {
        var task = tasks.get(this);
        if (!task) {
          task = factory.createTask(this);
          tasks.set(this, task);
        }
        return task;
      }
    };
  }
  function taskGroupPropertyDescriptor(target, key, _descriptor) {
    var params = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    var taskGroups = new WeakMap();
    var options = params[0] || {};
    var factory = new _taskFactory.TaskFactory(key, null, options);
    return {
      get: function get() {
        var task = taskGroups.get(this);
        if (!task) {
          task = factory.createTaskGroup(this);
          taskGroups.set(this, task);
        }
        return task;
      }
    };
  }

  // Cribbed from @ember-decorators/utils
  function isFieldDescriptor(possibleDesc) {
    var _possibleDesc = _slicedToArray(possibleDesc, 3),
      target = _possibleDesc[0],
      key = _possibleDesc[1],
      desc = _possibleDesc[2];
    return possibleDesc.length === 3 && _typeof(target) === 'object' && target !== null && typeof key === 'string' && (_typeof(desc) === 'object' && desc !== null && 'enumerable' in desc && 'configurable' in desc || desc === undefined) // TS compatibility ???
    ;
  }
  function decoratorWithParams(descriptorFn) {
    return function () {
      for (var _len = arguments.length, params = new Array(_len), _key = 0; _key < _len; _key++) {
        params[_key] = arguments[_key];
      }
      if (isFieldDescriptor(params)) {
        return descriptorFn.apply(void 0, params);
      } else {
        return function () {
          for (var _len2 = arguments.length, desc = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
            desc[_key2] = arguments[_key2];
          }
          return descriptorFn.apply(void 0, desc.concat([params]));
        };
      }
    };
  }
  function createDecorator(fn) {
    var baseOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return decoratorWithParams(function (target, key, descriptor) {
      var _ref = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [],
        _ref2 = _slicedToArray(_ref, 1),
        userOptions = _ref2[0];
      var mergedOptions = Object.assign({}, _objectSpread(_objectSpread({}, baseOptions), userOptions));
      return fn(target, key, descriptor, [mergedOptions]);
    });
  }
  var lastValue = decoratorWithParams(function (target, key, descriptor) {
    var _ref3 = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [],
      _ref4 = _slicedToArray(_ref3, 1),
      taskName = _ref4[0];
    var initializer = descriptor.initializer;
    delete descriptor.initializer;
    if (_utils.USE_TRACKED) {
      return {
        get: function get() {
          var lastInstance = this[taskName].lastSuccessful;
          if (lastInstance) {
            return lastInstance.value;
          }
          if (initializer) {
            return initializer.call(this);
          }
          return undefined;
        }
      };
    } else {
      var cp = (0, _object.computed)("".concat(taskName, ".lastSuccessful"), function () {
        var lastInstance = (0, _object.get)(this, "".concat(taskName, ".lastSuccessful"));
        if (lastInstance) {
          // eslint-disable-next-line ember/no-get
          return (0, _object.get)(lastInstance, 'value');
        }
        if (initializer) {
          return initializer.call(this);
        }
        return undefined;
      });
      return cp(target, key, descriptor);
    }
  });

  /**
   * A Task is a cancelable, restartable, asynchronous operation that
   * is driven by a generator function. Tasks are automatically canceled
   * when the object they live on is destroyed (e.g. a Component
   * is unrendered).
   *
   * Turns the decorated generator function into a task.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, `group` or `keepLatest`.
   *
   * By default, tasks have no concurrency constraints
   * (multiple instances of a task can be running at the same time)
   * but much of a power of tasks lies in proper usage of Task Modifiers
   * that you can apply to a task.
   *
   * You can also define an
   * <a href="/docs/advanced/encapsulated-task">Encapsulated Task</a>
   * by decorating an object that defines a `perform` generator
   * method.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task } from 'ember-concurrency';
   *
   * class MyComponent extends Component {
   *   &#64;task
   *   *plainTask() {}
   *
   *   &#64;task({ maxConcurrency: 5, keepLatest: true, cancelOn: 'click' })
   *   *taskWithModifiers() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}] Task modifier options
   * @param {string|string[]} [options.cancelOn] Events to cancel task on. Applies only to `&#64;ember/component`
   * @param {boolean} [options.enqueue] Sets `enqueue` modifier on task if `true`
   * @param {boolean} [options.evented] Enables [task lifecycle events](/docs/advanced/lifecycle-events) for this Task, if `true`
   * @param {boolean} [options.debug] Enables task debugging if `true`
   * @param {boolean} [options.drop] Sets `drop` modifier on task if `true`
   * @param {string} [options.group] Associates task with the group specified
   * @param {boolean} [options.keepLatest] Sets `keepLatest` modifier on task if `true`
   * @param {number} [options.maxConcurrency] Sets the maximum number of running task instances for the task
   * @param {string|string[]} [options.observes] Properties to watch and cause task to be performed when they change
   * @param {string|string[]} [options.on] Events to perform task on. Applies only to `&#64;ember/component`
   * @param {function?} [options.onState] Callback to use for state tracking. May be set to `null` to disable state tracking.
   * @param {boolean} [options.restartable] Sets `restartable` modifier on task if `true`
   * @return {Task}
   */
  _exports.lastValue = lastValue;
  var task = createDecorator(taskFromPropertyDescriptor);

  /**
   * Turns the decorated generator function into a task and applies the
   * `drop` modifier.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, or `group`.
   *
   * You can also define an
   * <a href="/docs/advanced/encapsulated-task">Encapsulated Task</a>
   * by decorating an object that defines a `perform` generator
   * method.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task, dropTask } from 'ember-concurrency';
   *
   * class MyComponent extends Component {
   *   &#64;task
   *   *plainTask() {}
   *
   *   &#64;dropTask({ cancelOn: 'click' })
   *   *myDropTask() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}] Task modifier options. See {@link task} for list.
   * @return {Task}
   */
  _exports.task = task;
  var dropTask = createDecorator(taskFromPropertyDescriptor, {
    drop: true
  });

  /**
   * Turns the decorated generator function into a task and applies the
   * `enqueue` modifier.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, or `group`.
   *
   * You can also define an
   * <a href="/docs/advanced/encapsulated-task">Encapsulated Task</a>
   * by decorating an object that defines a `perform` generator
   * method.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task, enqueueTask } from 'ember-concurrency';
   *
   * class MyComponent extends Component {
   *   &#64;task
   *   *plainTask() {}
   *
   *   &#64;enqueueTask({ cancelOn: 'click' })
   *   *myEnqueueTask() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}] Task modifier options. See {@link task} for list.
   * @return {Task}
   */
  _exports.dropTask = dropTask;
  var enqueueTask = createDecorator(taskFromPropertyDescriptor, {
    enqueue: true
  });

  /**
   * Turns the decorated generator function into a task and applies the
   * `keepLatest` modifier.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, or `group`.
   *
   * You can also define an
   * <a href="/docs/advanced/encapsulated-task">Encapsulated Task</a>
   * by decorating an object that defines a `perform` generator
   * method.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task, keepLatestTask } from 'ember-concurrency';
   *
   * class MyComponent extends Component {
   *   &#64;task
   *   *plainTask() {}
   *
   *   &#64;keepLatestTask({ cancelOn: 'click' })
   *   *myKeepLatestTask() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}] Task modifier options. See {@link task} for list.
   * @return {Task}
   */
  _exports.enqueueTask = enqueueTask;
  var keepLatestTask = createDecorator(taskFromPropertyDescriptor, {
    keepLatest: true
  });

  /**
   * Turns the decorated generator function into a task and applies the
   * `restartable` modifier.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task. For instance `maxConcurrency`, `on`, or `group`.
   *
   * You can also define an
   * <a href="/docs/advanced/encapsulated-task">Encapsulated Task</a>
   * by decorating an object that defines a `perform` generator
   * method.
   *
   * ```js
   * import Component from '@ember/component';
   * import { task, restartableTask } from 'ember-concurrency';
   *
   * class MyComponent extends Component {
   *   &#64;task
   *   *plainTask() {}
   *
   *   &#64;restartableTask({ cancelOn: 'click' })
   *   *myRestartableTask() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}] Task modifier options. See {@link task} for list.
   * @return {Task}
   */
  _exports.keepLatestTask = keepLatestTask;
  var restartableTask = createDecorator(taskFromPropertyDescriptor, {
    restartable: true
  });

  /**
   * "Task Groups" provide a means for applying
   * task modifiers to groups of tasks. Once a {@linkcode Task} is declared
   * as part of a group task, modifiers like `drop` or `restartable`
   * will no longer affect the individual `Task`. Instead those
   * modifiers can be applied to the entire group.
   *
   * Turns the decorated property into a task group.
   *
   * Optionally takes a hash of options that will be applied as modifiers to the
   * task group. For instance `maxConcurrency` or `keepLatest`.
   *
   * ```js
   * import Component from '@glimmer/component';
   * import { task, taskGroup } from 'ember-concurrency';
   *
   * class MyComponent extends Component {
   *   &#64;taskGroup({ maxConcurrency: 5 }) chores;
   *
   *   &#64;task({ group: 'chores' })
   *   *mowLawn() {}
   *
   *   &#64;task({ group: 'chores' })
   *   *doDishes() {}
   * }
   * ```
   *
   * @function
   * @param {object?} [options={}] Task group modifier options. See {@link task} for list.
   * @return {TaskGroup}
   */
  _exports.restartableTask = restartableTask;
  var taskGroup = createDecorator(taskGroupPropertyDescriptor);

  /**
   * Turns the decorated property into a task group and applies the
   * `drop` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}] Task group modifier options. See {@link task} for list.
   * @return {TaskGroup}
   */
  _exports.taskGroup = taskGroup;
  var dropTaskGroup = createDecorator(taskGroupPropertyDescriptor, {
    drop: true
  });

  /**
   * Turns the decorated property into a task group and applies the
   * `enqueue` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}] Task group modifier options. See {@link task} for list.
   * @return {TaskGroup}
   */
  _exports.dropTaskGroup = dropTaskGroup;
  var enqueueTaskGroup = createDecorator(taskGroupPropertyDescriptor, {
    enqueue: true
  });

  /**
   * Turns the decorated property into a task group and applies the
   * `keepLatest` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}] Task group modifier options. See {@link task} for list.
   * @return {TaskGroup}
   */
  _exports.enqueueTaskGroup = enqueueTaskGroup;
  var keepLatestTaskGroup = createDecorator(taskGroupPropertyDescriptor, {
    keepLatest: true
  });

  /**
   * Turns the decorated property into a task group and applies the
   * `restartable` modifier.
   *
   * Optionally takes a hash of further options that will be applied as modifiers
   * to the task group.
   *
   * @function
   * @param {object?} [options={}] Task group modifier options. See {@link task} for list.
   * @return {TaskGroup}
   */
  _exports.keepLatestTaskGroup = keepLatestTaskGroup;
  var restartableTaskGroup = createDecorator(taskGroupPropertyDescriptor, {
    restartable: true
  });
  _exports.restartableTaskGroup = restartableTaskGroup;
});