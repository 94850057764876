define("ember-sortable/components/sortable-handle", ["exports", "@ember/component", "@ember/debug"], function (_exports, _component, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * This component represents the handle of a `sortable-item`.
   */
  var _default = _component.default.extend({
    tabindex: 0,
    role: 'button',
    attributeBindings: ["dataSortableHandle:data-sortable-handle", "tabindex", "role"],
    dataSortableHandle: true,
    init: function init() {
      this._super.apply(this, arguments);
      (false && !(false) && (0, _debug.deprecate)('The <SortableHandle> component is deprecated.  Please use the modifier version {{sortable-handle}}.', false, {
        id: 'ember-sortable:sortable-handle-component-deprecated',
        until: '3.0.0',
        for: 'ember-sortable',
        since: {
          available: '2.2.6',
          enabled: '2.2.6'
        }
      }));
    }
  });
  _exports.default = _default;
});