define("ember-file-upload/system/parse-json", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseJSON;
  function parseJSON(string) {
    var json = null;
    try {
      json = JSON.parse(string);
    } catch (e) {
      (false && !(false) && (0, _debug.assert)("Invalid JSON: ".concat(string)));
    }
    return json;
  }
});